<template>
  <b-card>
    <b-row>
      <b-col>
        <h1 class="mb-2">
          Create a new customer
        </h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <validation-observer
          ref="createValidation"
        >
          <b-form
            class="d-flex flex-column"
            @submit="onSubmit"
          >
            <b-form-group
              label="Name:"
              label-for="input-name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.name"
                  placeholder="Enter your name"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Email:"
              label-for="input-email"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|email"
              >
                <b-form-input
                  id="input-email"
                  v-model="form.email"
                  placeholder="Enter your email"
                  type="email"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Phone number:"
              label-for="input-phone"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required|min:12"
              >
                <phone-mask-input
                  v-model="form.phone"
                  defaultCountry="us"
                  inputClass="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="align-self-end w-xs-100 w-25"
              :disabled="isCreateLoading"
            >
              <b-spinner
                v-if="isCreateLoading"
                label="Spinning"
                small
              />
              <span v-else>
                Create
              </span>
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BSpinner,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { toastSuccess, toastFailure } from '@/libs/toastification'
import PhoneMaskInput from  "vue-phone-mask-input"
import {sweetWarning, sweetError} from "@/libs/sweet-alerts";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: '+1',
      },
      required,
      email,
      isCreateLoading: false,
    }
  },
  methods: {
    ...mapActions({
      createUser: 'auth/createUser',
    }),
    showError(responseData=null, warning=false) {
      if( responseData != null && responseData.message != undefined && responseData.message != null ){
        let errorMSG = responseData.message + '</br>';
        if(responseData.errors){
          Object.keys(responseData.errors).map(function(errorKey){
            responseData.errors[errorKey].map(function(value, key){
              errorMSG += value + '</br>';
            });
          });
        }
        if( warning ){
          sweetWarning('Warning', errorMSG);
        }
        else{
          sweetError('Error', errorMSG);
        }
      }
      else{
        toastFailure("Something went wrong while creating a customer.");
      }
    },
    onSubmit(event) {
      event.preventDefault()
      this.$refs.createValidation.validate().then(success => {
        if (success) {
          this.isCreateLoading = true
          this.submitForm()
        }
      })
    },
    submitForm() {
      this.createUser(this.form)
        .then((response) => {

          if( response.data.email_sent === false ){
            let emailError = {
              message:'Customer successfully created, but we couldn\'t send the email because of technical error.'
            };
            this.showError(emailError, 1);
          }
          else{
            toastSuccess('A new customer has been created');
          }

          this.$router.replace({ name: 'new-booking' })
        })
        .catch((error) => {
          console.log('error', error);
          this.showError(error.response.data);
          //toastFailure('Something went wrong')
        })
        .finally(() => {
          this.isCreateLoading = false
        })
    },
  },
}
</script>

<style>

</style>
